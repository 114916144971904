<template lang="pug">
.cm-config.cm-area(:class="{ 'cm-config--expanded': expanded }")
  .cm-config__wrapper
    .cm-spacing.cm-header-cell
      .d-flex.justify-space-between.align-center.cm-config__header
        .cm-config__title Add exam
        formatExamProvider(v-slot="formatted", @formatted="addNew")
          configCreateProvider(@create="formatted.getFormattingExam({examID: $event.ID, courseID: +$route.query.course_id})" v-slot="create")
            configCreateValidator(@validated="create.checkValidatorBeforeSend" v-slot="validator")
              cityPrefillProvider(:create-showing="create.showing" @city:update="create.updateFormData")
                v-dialog(
                  max-width="400px"
                  :value="create.showing"
                  @input="create.updateShowing"
                )
                  template(v-slot:activator="{on}")
                    v-btn(icon v-on="on" color="primary" :disabled="!hasCourseID" x-small data-test="cm-create-open")
                      v-icon(size="24") mdi-plus

                  modal-body(
                    :editData="create.formData"
                    :formErrors="create.formErrors"
                    :courseDate="activeCourse.date"
                    :showing="create.showing"
                    @back="create.toggle"
                    title="Add exam"
                    isCreateExam
                  )
                    template(v-slot:actions="modalProps")
                      v-spacer
                      v-btn(outlined width="80" @click="create.toggle").btn-common Cancel
                      v-btn(
                        color="primary"
                        width="80"
                        :loading="create.processing"
                        @click="validator.validationBeforeSend({data: modalProps.formData, courseID: +$route.query.course_id})"
                        data-test="cm-create-submit"
                      ).btn-common Add
    reservationOptionsSelector
    configEditProvider(:courseID="+$route.query.course_id" @updated="updateItem" @deleted="removeItem" v-slot="edit")
      configCreateValidator(@validated="edit.checkValidatorBeforeSend" v-slot="validator")
        .cm-spacing.cm-config__body

          max-students(
            :maxStudents="activeCourse.maxStudents"
            :ID="activeCourse.ID"
            @updated="loadCourses"
          )
            template(v-slot:title)
              .max-students.cm-config__title.hover-pointer.d-flex.justfiy-space-between.mb-2
                | Max students - {{ activeCourse.maxStudents }}
          .cm-config__preloader(data-test="cm-config-preloader" v-if="loading")
            v-skeleton-loader(
              data-test="cm-config-preloader"
              type="card-heading, divider, list-item-three-line" elevation="2"
              v-for="loader in [1,2,3]" :key="loader"
            ).cm-config__preloader
          config(
            v-for="item in list"
            :key="item.ID"
            :item="item"
            :examFilter="!isMijnCBR"
            @click:filter="$emit('update:exam')"
            @click:edit="edit.openSelected"
          )
          course-notes(
            v-if="activeCourse && activeCourse.ID > -1"
            :courseID="activeCourse.ID"
            v-slot="notes"
            @updated="onCourseNoteUpdate"
          )
            v-textarea( v-if="activeCourse.item" label="Notes" outlined :value="activeCourse.item.notes" @change="notes.send").notes

          v-dialog(
            max-width="400px"
            :value="edit.showing"
            @input="edit.updateShowing"
          )
            modal-body(
              :editData="edit.formData"
              :formErrors="edit.formErrors"
              :showing="edit.showing"
              :key="`${edit.showing}edit`"
              @back="edit.toggle"
              title="Edit exam"
              isCreateExam
            )
              template(v-slot:actions="modalProps")
                showingProvider(
                  v-slot="showingProvider"
                )
                  span
                    confirmation-dialog(
                      v-model="showingProvider.showing"
                      @okBtnClicked="edit.remove({settingID: modalProps.formData.ID, courseID: +$route.query.course_id})"
                      @failBtnClicked="showingProvider.toggle"
                      title="Delete exam"
                      descr="Are you sure you want to delete exam? It will be removed from the candidates in queue."
                      okText="ok"
                      failText="cancel"
                    )
                    v-btn(
                      outlined
                      color="primary"
                      :loading="edit.processing"
                      @click="showingProvider.toggle"
                    ).btn-common
                      v-icon mdi-delete
                v-spacer
                v-btn(outlined width="80" @click="edit.toggle").btn-common Cancel
                v-btn(
                  color="primary"
                  width="80"
                  :loading="edit.processing"
                  @click="validator.validationBeforeSend({settingID: modalProps.formData.ID, data: modalProps.formData, courseID: +$route.query.course_id})"
                  data-test="cm-create-submit"
                ).btn-common edit
  div.cm-config__expand(@click="toggleSidebar")
    v-icon(color="white") {{ !expanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { CANDIDATE_PAGE } from '../../candidates/core/candidates-const'

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('cm');

export default {
  inject: ['svc'],

  data: () => ({
    list: [],
    loading: false,
    expanded: false
  }),

  computed: {
    ...mapGetters({
      activeCourse: 'activeSingleCourse'
    }),
    hasCourseID() {
      return !!this.$route.query.course_id
    },
    isMijnCBR() {
      return this.$route.query.page_name === CANDIDATE_PAGE.MIJN_CBR_QUEUE
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    '$route.query.license_type'() {
      this.loadData()
    }
  },

  methods: {
    ...mapActions({
      courseListDetailed: 'courseListDetailed'
    }),

    onCourseNoteUpdate(note) {
      this.setCourseNote({
        note,
        courseId: this.activeCourse.ID
      })
    },

    loadCourses() {
      this.courseListDetailed({ query: {...this.$route.query, size: 500} })
    },

    loadData() {
      if(this.$route.query.course_id)
        this.loadList(this.$route.query.course_id)
    },

    async loadList(courseID) {
      try {
        this.loading = true
        const query = {
          ...(this.$route.query.license_type ? { license_type: this.$route.query.license_type } : {}),
        }
        let list = await this.svc.getConfig(courseID, query)
        this.list = list
      } catch (error) {
        console.log(error)
      }
      finally {
        this.loading = false
      }
    },

    addNew(item) {
      this.list.unshift(item)
    },

    updateItem(newItem) {
      let updateItemIndex = this.list.findIndex(item => item.ID === newItem.ID )
      if(updateItemIndex < 0) return
      this.list[updateItemIndex] = newItem
    },

    removeItem(removedID) {
      let removedItemIndex = this.list.findIndex(item => item.ID === removedID )
      if(removedItemIndex < 0) return
      this.list.splice(removedItemIndex, 1)
    },

    toggleSidebar() {
      this.expanded = !this.expanded;
    },

    ...mapMutations({
      setCourseNote: 'updateCourseNote'
    })
  },

  components: {
    config: () => import('./CMExamItem.vue'),
    modalBody: () => import('./cm_exam/CMExamModalBody.vue'),
    formatExamProvider: () => import('./cm_exam/CMExamAddFormattingProvider.vue'),
    configCreateProvider: () => import('./cm_exam/CMExamAddProvider.vue'),
    cityPrefillProvider: () => import('./cm_exam/CMExamCityPrefillProvider.vue'),
    configCreateValidator: () => import('./cm_exam/CMCreateValidationProvider.vue'),
    configEditProvider: () => import('./cm_exam/CMExamEditProvider.vue'),
    courseNotes: () => import('./cm_course/CMCourseNotes.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
    maxStudents: () => import('./cm_course/CMMaxStudents.vue'),
    reservationOptionsSelector: () => import('./CMReservationOptionsSelector.vue')
  }
}
</script>

<style lang="scss" scoped>
.cm-config {
  overflow: visible;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 0;
    min-width: 0;
    background-color: #fff;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -5px 0 14px -3px rgba(0,0,0,0.5);
    &--expanded {
      width: 192px!important;
      min-width: 192px!important;
    }
  }
  &__title {
    @extend %title-subsection;
  }
  &__space-fixator {
    width: 145px;
    height: 1px;
  }
  &__header {
    width: 145px;
  }
  &__body {
    padding-top: 10px;
  }
  &__preloader {
    margin-bottom: 14px;
    width: 145px;
  }
  &__wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  &__expand {
    position: absolute;
    top: 0;
    left: -32px;
    background-color: $primary-color;
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}

.notes::v-deep {
  .v-text-field__slot {
    width: 145px;
  }
}
</style>
